<template>
  <div>
    view
    <button type="button" @click="facebookShare">click</button>
    <br />
  </div>
</template>
<script>
  export default {
    name: "testView",
    data() {
      return {
        testData: false
      };
    },
    provide() {
      const my = {};
      Object.defineProperty(my, "testData", {
        enumerable: true,
        get: () => this.testData
      });
      return {my};
    },
    methods: {
      toggle() {
        this.testData = !this.testData;
      },
      facebookShare() {
        //window.open(`http://www.facebook.com/share.php?u=https://www.naver.com/`);
        window.open(`http://www.facebook.com/share.php?u=${window.location.href}`);
      }
    }
  };
</script>
<style scoped></style>
